<template>
  <client-only v-if="!isConsentGivenForForm">
    <p class="blocked-form__message text-body-sm">
      This feature is not working due to disabled
      {{ disabledCookieCategoriesFormatted }}
      cookies. To use this and other services, please enable
      <span
        class="blocked-form__cookies-trigger text-underline"
        @click="$_oneTrust_toggleCookiePreferencesPopup"
        >cookies</span
      >.
    </p>
  </client-only>
</template>

<script>
import mixins from '@/utils/mixins'
import {
  COOKIE_CATEGORY_TITLE_BY_COOKIE_CATEGORY,
  COOKIE_ENTITY_TYPE,
  REQUIRED_COOKIE_CATEGORIES_BY_COOKIE_ENTITY_TYPE
} from 'enums/oneTrust'
import { PROP_TYPES, propValidator } from '@fmpedia/validators'

export default {
  name: 'ABlockedFormMessage',
  mixins: [mixins.oneTrust],
  props: {
    cookieEntityTypes: propValidator(
      [PROP_TYPES.ARRAY],
      false,
      () => [COOKIE_ENTITY_TYPE.RE_CAPTCHA],
      entities =>
        entities.every(entity =>
          Object.values(COOKIE_ENTITY_TYPE).includes(entity)
        )
    )
  },
  computed: {
    isConsentGivenForForm() {
      return this.cookieEntityTypes.every(
        this.$_oneTrust_isConsentGivenForEntity
      )
    },
    disabledCookieCategories() {
      const allCategories = this.cookieEntityTypes.reduce((acc, entity) => {
        const categories =
          REQUIRED_COOKIE_CATEGORIES_BY_COOKIE_ENTITY_TYPE[entity]
        acc.push(...categories)

        return acc
      }, [])

      return [...new Set(allCategories)]
    },
    disabledCookieCategoriesFormatted() {
      const arr = this.disabledCookieCategories.map(
        category => COOKIE_CATEGORY_TITLE_BY_COOKIE_CATEGORY[category]
      )

      if (arr.length === 1) return arr[0]
      if (arr.length === 2) return `${arr[0]} or ${arr[1]}`
      return `${arr.slice(0, -1).join(', ')}, or ${arr[arr.length - 1]}`
    }
  }
}
</script>

<style lang="scss" scoped>
.blocked-form__message {
  color: $c--error;
  font-size: 11px;

  .blocked-form__cookies-trigger {
    cursor: pointer;
  }
}
</style>
