<template>
  <div class="form" v-observe-visibility="visibilityOptions">
    <div v-if="inProgress" class="form__overlay" />

    <a-modal-wrapper
      :modal-name="successMessageModal"
      :width="559"
      close-button
    >
      <a-success-message :id="successMsgId" v-bind="successMessageProps" />
    </a-modal-wrapper>

    <a-success-message
      v-if="isSuccessMsgVisible"
      :id="successMsgId"
      v-bind="successMessageProps"
    />

    <form v-else @submit.prevent="onSubmit" v-bind="formAttrs">
      <h2 v-if="title" :class="titleClasses">
        {{ title }}
      </h2>
      <p v-if="topText" :class="topTextClasses">
        {{ topText }}
      </p>

      <div :key="slotKey">
        <slot />
      </div>

      <div class="form__row">
        <a-gdpr
          class="form__gdpr"
          :hide-checkbox="hideGdprCheckbox"
          :gdpr="formSettings.Gdpr"
          :value="isTermAccepted"
          :error="errors.isTermAccepted"
          @input="$emit('update:isTermAccepted', $event)"
        />
      </div>

      <div class="form__captcha-and-button-wrapper">
        <a-invisible-captcha
          v-if="isCaptchaEnabled"
          ref="invisibleCaptcha"
          :captcha-key="captchaKey"
          :sitekey="captchaSiteKey"
          @update:captchaKey="$emit('update:captchaKey', $event)"
          @verify="onVerifyCaptcha"
        />
        <a-google-captcha-terms-of-use />
        <a-blocked-form-message
          :cookie-entity-types="cookieEntityTypes"
          class="form__captcha-blocked-message"
        />

        <div v-if="!hideSubmitButton" class="form__row">
          <slot
            v-if="$slots.buttons || $scopedSlots.buttons"
            name="buttons"
            :in-progress="inProgress"
            :is-captcha-loaded="isCaptchaLoaded"
            :disabled="isButtonDisabled"
          />
          <a-button
            v-else
            type="submit"
            :in-progress="inProgress"
            :disabled="isButtonDisabled"
            class="form__submit-button"
          >
            {{ submitButtonText }}
          </a-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { pathOr } from 'ramda'
import { mapGetters } from 'vuex'

import { ICON } from 'shared/AIcon'
import AGdpr from 'shared/AGdpr'
import ASuccessMessage from 'shared/ASuccessMessage'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { FORM_TYPE } from 'enums/form-type'
import AGoogleCaptchaTermsOfUse from 'shared/AGoogleCaptchaTermsOfUse/index'
import AInvisibleCaptcha from 'shared/AInvisibleCaptcha/index'
import AModalWrapper from '@/components/_modals/AModalWrapper'
import mixins from '@/utils/mixins'
import ABlockedFormMessage from 'shared/ABlockedFormMessage'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'

export { FORM_TYPE }

const TITLE_CLASS_MODIFIER = {
  [FORM_TYPE.CONTACT_US]: 'form__title_md subtitle-1',
  [FORM_TYPE.NEWSLETTER]: 'form__title_md subtitle-1',
  [FORM_TYPE.EVENTS_MAILING_LIST]: 'form__title_md subtitle-1',
  [FORM_TYPE.EVENTS_STICKY_BUTTON]: 'form__title_md subtitle-1',
  [FORM_TYPE.INTELLIGENCE_CUSTOM_REPORT]: 'form__title_md subtitle-1',
  [FORM_TYPE.INTELLIGENCE_BUY_HERE]: 'form__title_md subtitle-1',
  [FORM_TYPE.INTELLIGENCE_ANNUAL_SUBSCRIPTION]: 'form__title_md subtitle-1',
  [FORM_TYPE.BECOME_AN_AUTHOR]: 'form__title_lg',
  [FORM_TYPE.HP_NEWSLETTER]: 'subtitle-2 normal form__title_legend'
}

const TOP_TEXT_CLASS_MODIFIER = {
  [FORM_TYPE.CONTACT_US]: 'form__top-text_md text-body',
  [FORM_TYPE.NEWSLETTER]: 'form__top-text_md text-body',
  [FORM_TYPE.EVENTS_MAILING_LIST]: 'form__top-text_md text-body',
  [FORM_TYPE.EVENTS_STICKY_BUTTON]: 'form__top-text_md text-body',
  [FORM_TYPE.INTELLIGENCE_CUSTOM_REPORT]: 'form__top-text_md text-body',
  [FORM_TYPE.INTELLIGENCE_BUY_HERE]: 'form__top-text_md text-body',
  [FORM_TYPE.INTELLIGENCE_ANNUAL_SUBSCRIPTION]: 'form__title_md text-body',
  [FORM_TYPE.BECOME_AN_AUTHOR]: 'form__top-text_lg'
}

const FORM_TYPE_VALUES = Object.values(FORM_TYPE)

const DEFAULT_ICON = ICON.SENT_LETTER

export default {
  name: 'AForm',
  mixins: [mixins.captchaLazyLoad, mixins.oneTrust],
  components: {
    ABlockedFormMessage,
    AInvisibleCaptcha,
    AGoogleCaptchaTermsOfUse,
    ASuccessMessage,
    AGdpr,
    AModalWrapper
  },
  props: {
    formType: propValidator([PROP_TYPES.STRING], false, null, type =>
      FORM_TYPE_VALUES.includes(type)
    ),
    errors: propValidator([PROP_TYPES.OBJECT], false, () => ({})),
    captchaKey: propValidator([PROP_TYPES.STRING], false, ''),
    captchaSiteKey: propValidator([PROP_TYPES.STRING], false, ''),
    disableCaptcha: propValidator([PROP_TYPES.BOOLEAN], false, false),
    isTermAccepted: propValidator([PROP_TYPES.BOOLEAN], false, false),
    hideGdprCheckbox: propValidator([PROP_TYPES.BOOLEAN], false, false),
    successMsgDisplayTime: propValidator([PROP_TYPES.NUMBER], false, 3000),
    scrollToSuccessMessage: propValidator([PROP_TYPES.BOOLEAN], false, false),
    inProgress: propValidator([PROP_TYPES.BOOLEAN], false, false),
    submitButtonText: propValidator([PROP_TYPES.STRING], false, 'Submit'),
    hideTitle: propValidator([PROP_TYPES.BOOLEAN], false, false),
    hideDescription: propValidator([PROP_TYPES.BOOLEAN], false, false),
    successMsgId: propValidator([PROP_TYPES.STRING], false, 'success-message'),
    prefilledForm: propValidator([PROP_TYPES.BOOLEAN], false, true),
    /**
     * You can pass external settins instead of getting them from a backend
     * @param {Object} externalSettings         - external settings object
     * @param {string} externalSettings.Title   - form title
     * @param {string} externalSettings.TopText - top text
     * @param {string} externalSettings.Gdpr    - Gdpr html text
     **/
    externalSettings: propValidator([PROP_TYPES.OBJECT], false, null),
    hideSubmitButton: propValidator([PROP_TYPES.BOOLEAN], false, false),

    /**
     * When we use captcha, we need to know the current state of form validation,
     * since there is no point in executing a captcha when form is invalid
     */
    formValidationFn: propValidator([PROP_TYPES.FUNCTION], false, () => () =>
      true
    ),
    successMessageModal: propValidator([PROP_TYPES.STRING], false, ''),
    formId: propValidator([PROP_TYPES.STRING], false),
    cookieEntityTypes: propValidator(
      [PROP_TYPES.ARRAY],
      false,
      COOKIE_ENTITY_TYPE.RE_CAPTCHA,
      entities =>
        entities.every(entity =>
          Object.values(COOKIE_ENTITY_TYPE).includes(entity)
        )
    )
  },
  data() {
    return {
      successMessagesIcons: {
        [FORM_TYPE.NEWSLETTER]: ICON.CHECK_MARK_ROUND_BLACK
      },
      slotKey: 'initial-key',
      isSuccessMsgVisible: false,
      successMessageTimeoutId: null,
      successMessageCloseResolver: null
    }
  },
  computed: {
    ...mapGetters({
      getFormSettingsByType: 'leads/getFormSettingsByType',
      userDetails: 'auth/userDetails',
      isLoggedIn: 'auth/isLoggedIn'
    }),
    isButtonDisabled() {
      return (
        !this.isCaptchaLoaded ||
        !this.cookieEntityTypes.some(this.$_oneTrust_isConsentGivenForEntity)
      )
    },
    formAttrs() {
      return {
        ...(this.formId ? { id: this.formId } : {})
      }
    },
    visibilityOptions() {
      if (this.disableCaptcha) return false

      return this.formVisibilityOptions
    },
    isCaptchaEnabled() {
      return this.isCaptchaLoaded && !this.disableCaptcha
    },
    formSettings() {
      return this.externalSettings || this.getFormSettingsByType(this.formType)
    },
    title() {
      return this.hideTitle ? null : this.formSettings.Title
    },
    titleClasses() {
      return ['form__title', TITLE_CLASS_MODIFIER[this.formType]]
    },
    topTextClasses() {
      return ['form__top-text', TOP_TEXT_CLASS_MODIFIER[this.formType]]
    },
    topText() {
      return this.hideDescription ? null : this.formSettings.TopText
    },
    successMessageProps() {
      return {
        icon: pathOr(DEFAULT_ICON, [this.formType], this.successMessagesIcons),
        title: pathOr('Success', ['SuccessMessageTitle'], this.formSettings),
        text: pathOr('', ['SuccessMessageText'], this.formSettings)
      }
    }
  },
  methods: {
    pathOr,
    onSubmit() {
      if (this.disableCaptcha) {
        this.$emit('submit')
        return
      }

      if (this.formValidationFn()) {
        this.$refs.invisibleCaptcha.execute()
      }
    },
    onVerifyCaptcha() {
      this.$emit('submit')
    },
    scrollToSuccessMessageContainer() {
      this.$nextTick(() =>
        this.$scrollTo(`#${this.successMsgId}`, 500, { offset: -200 })
      )
    },
    showSuccessMessageInModal() {
      this.$helper.openModal(this.successMessageModal)

      return new Promise(resolve => {
        this.successMessageCloseResolver = resolve

        this.successMessageTimeoutId = setTimeout(() => {
          this.$helper.closeModal(this.successMessageModal)
          resolve()
          this.successMessageCloseResolver = null
        }, this.successMsgDisplayTime)
      })
    },
    showSuccessMessage() {
      this.isSuccessMsgVisible = true
      if (this.scrollToSuccessMessage) {
        this.scrollToSuccessMessageContainer()
      }

      return new Promise(resolve => {
        this.successMessageCloseResolver = resolve

        this.successMessageTimeoutId = setTimeout(() => {
          this.hideSuccessMessage()
          resolve()
          this.successMessageCloseResolver = null
        }, this.successMsgDisplayTime)
      })
    },
    hideSuccessMessage() {
      this.$nextTick(() => {
        this.isSuccessMsgVisible = false
      })
    },
    runPreFilledValuesWatcher() {
      const areDetailsFetched = !!this.userDetails.Id
      if (!this.prefilledForm || !this.isLoggedIn || areDetailsFetched) return

      this.$helper.watchAndExecuteOnce({
        ctx: this,
        field: 'userDetails',
        handlerFn: () => {
          this.slotKey = this.$helper.guid()
        },
        conditionFn: ({ Id }) => !!Id
      })
    }
  },
  beforeMount() {
    this.runPreFilledValuesWatcher()
  },
  beforeDestroy() {
    clearTimeout(this.successMessageTimeoutId)

    if (this.successMessageCloseResolver) {
      this.successMessageCloseResolver()
    }
  }
}
</script>

<style lang="scss">
.form {
  position: relative;

  .form__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  .form__title {
    &_md {
      margin: 0 0 10px 0;
    }

    &_lg {
      font-size: 38px;
      line-height: 50px;
      margin: 0 0 20px 0;

      @include mobile {
        font-size: 26px;
        line-height: 48px;
        margin: 0 0 10px 0;
      }
    }

    &_legend {
      margin-bottom: 20px;
      max-width: 476px;
    }
  }

  .form__top-text {
    margin: 0 0 18px 0;

    &_lg {
      font-size: 19px;
      line-height: 29px;
      font-weight: 300;

      @include mobile {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &_pre-line {
      white-space: pre-line;
    }
  }

  .form__row {
    position: relative;
    display: flex;
    margin-bottom: 30px;

    > *:not(:last-child) {
      margin-right: 20px;
    }

    @include mobile {
      flex-wrap: wrap;

      > *:not(:last-child) {
        margin-right: inherit;
        margin-bottom: 30px;
      }

      > .form__recaptcha {
        margin-bottom: 0;
      }

      .btn--link {
        font-size: 16px;
      }
    }
  }

  .form__row:last-child {
    margin-bottom: 0;
  }

  .form__submit-button {
    margin-top: 13px;

    @include mobile {
      width: 100%;
      margin: 10px auto 0;
    }
  }

  .form__row .form__checkbox .checkbox__label {
    color: #626262;

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        color: $c--gray-main;
      }
    }
  }

  .form__captcha-blocked-message {
    margin-bottom: 15px;
  }

  .form__gdpr {
    width: 100%;
    margin-top: 30px;
  }
}
</style>
