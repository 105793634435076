export const COOKIE_CATEGORY = {
  STRICTLY_NECESSARY: 'C0001',
  PERFORMANCE: 'C0002',
  FUNCTIONAL: 'C0003',
  TARGETING: 'C0004',
  SOCIAL_MEDIA: 'C0005',
  STACK_42: 'V2STACK42'
}

export const COOKIE_CATEGORY_TITLE_BY_COOKIE_CATEGORY = {
  [COOKIE_CATEGORY.STRICTLY_NECESSARY]: 'Strictly Necessary',
  [COOKIE_CATEGORY.PERFORMANCE]: 'Performance',
  [COOKIE_CATEGORY.FUNCTIONAL]: 'Functional',
  [COOKIE_CATEGORY.TARGETING]: 'Targeting',
  [COOKIE_CATEGORY.SOCIAL_MEDIA]: 'Social Media',
  [COOKIE_CATEGORY.STACK_42]: 'V2STACK42'
}

export const MAIN_COOKIE_CATEGORY_VALUES = [
  COOKIE_CATEGORY.STRICTLY_NECESSARY,
  COOKIE_CATEGORY.PERFORMANCE,
  COOKIE_CATEGORY.FUNCTIONAL,
  COOKIE_CATEGORY.TARGETING,
  COOKIE_CATEGORY.SOCIAL_MEDIA
]

export const COOKIE_ENTITY_TYPE = {
  DFP_BANNER: 'DFP_BANNER',
  INTERSTITIAL_BANNER: 'INTERSTITIAL_BANNER',
  VIDEO_BANNER: 'VIDEO_BANNER',
  DISQUS: 'DISQUS',
  RE_CAPTCHA: 'RE_CAPTCHA',
  YOUTUBE: 'YOUTUBE',
  ONE_SIGNAL: 'ONE_SIGNAL',
  BLUE_SNAP: 'BLUE_SNAP',
  TWITTER_CARD: 'TWITTER_CARD',
  ROBOTS_ROTATION: 'ROBOTS_ROTATION',
  FACEBOOK: 'FACEBOOK',
  GOOGLE_ANALYTICS: 'GOOGLE_ANALYTICS',
  CLARITY: 'CLARITY',

  /**
   * For unknown entities it's safe to assume that consent
   * to ALL cookie categories is required
   *
   * # Example: dynamic scripts/iframes fetched from BE (article body)
   */
  UNKNOWN: 'UNKNOWN'
}

const DFP_BANNER_REQUIRED_COOKIE_CATEGORIES = [COOKIE_CATEGORY.TARGETING]

/**
 * Full list of prior blocked services
 */
export const REQUIRED_COOKIE_CATEGORIES_BY_COOKIE_ENTITY_TYPE = {
  [COOKIE_ENTITY_TYPE.DFP_BANNER]: DFP_BANNER_REQUIRED_COOKIE_CATEGORIES,
  [COOKIE_ENTITY_TYPE.INTERSTITIAL_BANNER]: DFP_BANNER_REQUIRED_COOKIE_CATEGORIES,
  [COOKIE_ENTITY_TYPE.VIDEO_BANNER]: [COOKIE_CATEGORY.TARGETING],
  [COOKIE_ENTITY_TYPE.DISQUS]: [
    COOKIE_CATEGORY.FUNCTIONAL,
    COOKIE_CATEGORY.TARGETING,
    COOKIE_CATEGORY.PERFORMANCE
  ],
  [COOKIE_ENTITY_TYPE.RE_CAPTCHA]: [
    COOKIE_CATEGORY.FUNCTIONAL,
    COOKIE_CATEGORY.TARGETING
  ],
  [COOKIE_ENTITY_TYPE.YOUTUBE]: [
    COOKIE_CATEGORY.FUNCTIONAL,
    COOKIE_CATEGORY.TARGETING
  ],
  [COOKIE_ENTITY_TYPE.ONE_SIGNAL]: [
    COOKIE_CATEGORY.FUNCTIONAL,
    COOKIE_CATEGORY.PERFORMANCE,
    COOKIE_CATEGORY.TARGETING
  ],
  [COOKIE_ENTITY_TYPE.BLUE_SNAP]: [
    COOKIE_CATEGORY.FUNCTIONAL,
    COOKIE_CATEGORY.TARGETING,
    COOKIE_CATEGORY.PERFORMANCE
  ],
  [COOKIE_ENTITY_TYPE.TWITTER_CARD]: MAIN_COOKIE_CATEGORY_VALUES,
  [COOKIE_ENTITY_TYPE.ROBOTS_ROTATION]: [COOKIE_CATEGORY.FUNCTIONAL],
  [COOKIE_ENTITY_TYPE.FACEBOOK]: MAIN_COOKIE_CATEGORY_VALUES,
  [COOKIE_ENTITY_TYPE.GOOGLE_ANALYTICS]: [COOKIE_CATEGORY.PERFORMANCE],
  [COOKIE_ENTITY_TYPE.CLARITY]: [COOKIE_CATEGORY.PERFORMANCE],
  [COOKIE_ENTITY_TYPE.UNKNOWN]: MAIN_COOKIE_CATEGORY_VALUES
}

export const OPTANON_CONSENT_COOKIE_NAME = 'OptanonConsent'
export const OPTANON_ALERT_BOX_CLOSED_COOKIE_NAME = 'OptanonAlertBoxClosed'

export const HANDLER_TYPE = {
  GLOBAL: 'global',
  LOCAL: 'local'
}

export const HANDLER_TYPE_BY_ENTITY_TYPE = {
  [COOKIE_ENTITY_TYPE.DFP_BANNER]: HANDLER_TYPE.GLOBAL,
  [COOKIE_ENTITY_TYPE.INTERSTITIAL_BANNER]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.VIDEO_BANNER]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.DISQUS]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.RE_CAPTCHA]: HANDLER_TYPE.GLOBAL,
  [COOKIE_ENTITY_TYPE.YOUTUBE]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.ONE_SIGNAL]: HANDLER_TYPE.GLOBAL,
  [COOKIE_ENTITY_TYPE.BLUE_SNAP]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.TWITTER_CARD]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.FACEBOOK]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.GOOGLE_ANALYTICS]: HANDLER_TYPE.GLOBAL,
  [COOKIE_ENTITY_TYPE.CLARITY]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.UNKNOWN]: HANDLER_TYPE.LOCAL
}

export const ONETRUST_POPUP_WRAPPER_ID = 'onetrust-consent-sdk'
